import gsap from 'gsap';
import ResizeObserver from 'resize-observer-polyfill';

window.ResizeObserver = ResizeObserver;

export default class Oyster {
  init() {
    this._tabs();
    this._accordion();
    this._tabsImage();
    this._imageCarousel();
    this._serverConfig();
  }

  _tabs() {
    let tabs = document.querySelectorAll('.tabs__tabs__item');
    let indicatorBar = document.querySelector('.tabs__tabs__indicator');
    let currentIndex = 0;
    let interval = 5000;
    let listAnimationTimeout;
    let isAllTab = document.querySelector("button[data-tab-all='true']");

    let tabsTl = gsap.timeline({});

    function changeTab() {
      let nextIndex = (currentIndex + 1) % tabs.length;
      if (!tabs[nextIndex]) return;
      if (document.hasFocus()) {
        tabs[nextIndex].click();
        currentIndex = nextIndex;
      } else {
        listAnimationTimeout = setTimeout(changeTab, interval);
      }
    }

    tabs.forEach(function (tab) {
      tab.addEventListener('click', function () {
        clearTimeout(listAnimationTimeout);
        let tabContent = document.querySelector(
          ".tabs__content__item[data-tab*='" +
          this.getAttribute('data-tab-id') +
          "']"
        );
        let nextIndex =
          (Number(this.getAttribute("data-tab-id")) - 1) % tabs.length;
        currentIndex = nextIndex;

        if (!this.classList.contains('is-active')) {
          // Assuming 'filterItem' and 'filteredItems' are previously defined
          tabs.forEach(function (otherTab) {
            otherTab.classList.remove('is-active');
          });
          this.classList.add('is-active');

          let tabsContent = document.querySelectorAll('.tabs__content__item');

          tabsTl
            .to(tabsContent, {
              duration: 0.3,
              alpha: 0,
              translateY: 10,
              ease: 'power3',
            })
            .set(tabsContent, {
              duration: 0,
              display: 'none',
              ease: 'power3',
            })
            .set(tabContent, {
              duration: 0,
              display: 'flex',
              ease: 'power3',
            })
            .set(tabContent, {
              duration: 0,
              display: 'flex',
              ease: 'power3',
            })
            .to(tabContent, {
              duration: 0.3,
              alpha: 1,
              translateY: 0,
              ease: 'power3',
            });
        }
        // Schedule the next opening
        listAnimationTimeout = setTimeout(changeTab, interval);

        indicator();
      });
    });

    indicator();

    function indicator() {
      let activeButton = document.querySelector('.tabs__tabs__item.is-active');
      if (!activeButton) return;
      let indicatorWidth = activeButton.offsetWidth;
      let indicatorOffset = activeButton.offsetLeft;

      indicatorBar.style.width = indicatorWidth + 'px';
      indicatorBar.style.transform = 'translateX(' + indicatorOffset + 'px)';
      indicatorBar.style.opacity = '1';
    }

    if (isAllTab) {
      listAnimationTimeout = setTimeout(changeTab, interval);
    }
  }


  _accordion() {
    let accordions = document.querySelectorAll('.accordion');

    accordions.forEach(function (accordion) {
      // let accordionItem = accordion.closest('.accordion');
      let accordionTrigger = accordion.querySelector('.accordion__trigger');
      let accordionContent = accordion.querySelector('.accordion__content');
      accordionTrigger.addEventListener('click', function () {
        let accordionTl = gsap.timeline();

        if (accordion.classList.contains('is-active')) {
          accordion.classList.remove('is-active');
          accordionTl.to(accordionContent, {
            duration: 0.3,
            height: 0,
            ease: 'power3',
          });
        } else {
          accordion.classList.add('is-active');
          accordionTl.to(accordionContent, {
            duration: 0.3,
            height: 'auto',
            ease: 'power3',
          });
        }
      });
    });
  }

  _tabsImage() {
    let tabs = document.querySelectorAll('.tabs-image__tabs__item');
    let indicatorBar = document.querySelector('.tabs-image__tabs__indicator');
    let tabsImageTl = gsap.timeline({});
    let currentIndex = 0;
    let interval = 5000;
    let listAnimationTimeout;

    function changeTab() {
      let nextIndex = (currentIndex + 1) % tabs.length;
      if (!tabs[nextIndex]) return;
      if (document.hasFocus()) {
        tabs[nextIndex].click();
        currentIndex = nextIndex;
      } else {
        listAnimationTimeout = setTimeout(changeTab, interval);
      }
    }

    tabs.forEach(function (tab) {
      tab.addEventListener('click', function () {
        clearTimeout(listAnimationTimeout);
        if (this.classList.contains('is-active')) {
          listAnimationTimeout = setTimeout(changeTab, interval);
          return;
        }
        let tabContentActive = document.querySelector(
          ".tabs-image__content__item[data-tab*='" +
          document
            .querySelector('.tabs-image__tabs__item.is-active')
            .getAttribute('data-tab-id') +
          "']"
        );
        let nextIndex =
          (Number(this.getAttribute("data-tab-id")) - 1) % tabs.length;
        currentIndex = nextIndex;

        // Remove and add 'is-active' class
        tabs.forEach(function (otherTab) {
          otherTab.classList.remove('is-active');
        });
        this.classList.add('is-active');

        let tabContent = document.querySelector(
          ".tabs-image__content__item[data-tab*='" +
          this.getAttribute('data-tab-id') +
          "']"
        );
        let video = tabContent.querySelector('video');
        video.pause();
        video.currentTime = 0;
        video.play();

        tabsImageTl
          .fromTo(
            tabContent,
            {
              alpha: 0,
            },
            { duration: 0.3, alpha: 1, ease: 'power3' }
          )
          .fromTo(
            tabContentActive,
            {
              alpha: 1,
            },
            { duration: 0.3, alpha: 0, ease: 'power3' },
            '-=0.3'
          );

        indicator();

        // Schedule the next opening
        listAnimationTimeout = setTimeout(changeTab, interval);
      });
    });

    indicator();

    function indicator() {
      let activeButton = document.querySelector(
        '.tabs-image__tabs__item.is-active'
      );
      if (!activeButton) return;
      let indicatorHeight = activeButton.offsetHeight;
      let indicatorOffset = activeButton.offsetTop;

      indicatorBar.style.height = indicatorHeight + 'px';
      indicatorBar.style.transform = 'translateY(' + indicatorOffset + 'px)';
      indicatorBar.style.opacity = '1';
    }

    if (tabs.length > 0) {
      indicator();

      window.addEventListener('resize', function () {
        indicator();
      });
    }

    listAnimationTimeout = setTimeout(changeTab, interval);
  }

  _imageCarousel() {
    let tabs = document.querySelectorAll('.image-carousel__left__list__item');
    let tabsImageTl = gsap.timeline({});
    let currentIndex = 0;
    let interval = 5000;
    let listAnimationTimeout;

    function changeTab() {
      let nextIndex = (currentIndex + 1) % tabs.length;
      if (!tabs[nextIndex]) return;
      if (document.hasFocus()) {
        tabs[nextIndex].click();
        currentIndex = nextIndex;
      } else {
        listAnimationTimeout = setTimeout(changeTab, interval);
      }
    }

    tabs.forEach(function (tab) {
      tab.addEventListener('click', function () {
        clearTimeout(listAnimationTimeout);
        if (this.classList.contains('is-active')) {
          listAnimationTimeout = setTimeout(changeTab, interval);
          return;
        }
        let tabContentActive = document.querySelector(
          ".image-carousel__right__item[data-tab*='" +
          document
            .querySelector('.image-carousel__left__list__item.is-active')
            .getAttribute('data-tab-id') +
          "']"
        );
        let nextIndex =
          (Number(this.getAttribute("data-tab-id")) - 1) % tabs.length;
        currentIndex = nextIndex;

        // Remove and add 'is-active' class
        tabs.forEach(function (otherTab) {
          otherTab.classList.remove('is-active');
        });
        this.classList.add('is-active');

        let tabContent = document.querySelector(
          ".image-carousel__right__item[data-tab*='" +
          this.getAttribute('data-tab-id') +
          "']"
        );
        let video = tabContent.querySelector('video');
        video.pause();
        video.currentTime = 0;
        video.play();

        tabsImageTl
          .fromTo(
            tabContent,
            {
              alpha: 0,
            },
            { duration: 0.3, alpha: 1, ease: 'power3' }
          )
          .fromTo(
            tabContentActive,
            {
              alpha: 1,
            },
            { duration: 0.3, alpha: 0, ease: 'power3' },
            '-=0.3'
          );

        // Schedule the next opening
        listAnimationTimeout = setTimeout(changeTab, interval);
      });
    });

    listAnimationTimeout = setTimeout(changeTab, interval);
  }


  _serverConfig() {

    function isInViewport(element) {
      // this function determines if the entire element is in the viewport w.r.t window
      const elementPosition = element.getBoundingClientRect();
      return (
        elementPosition.top >= 0 &&
        elementPosition.left >= 0 &&
        elementPosition.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        elementPosition.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function isElementCompletelyVisible(container, element) {
      // this function determines if the entire element is in the viewport w.r.t it's parent
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      const isVisibleVertically =
        elementRect.top >= containerRect.top &&
        elementRect.bottom <= containerRect.bottom;

      const isVisibleHorizontally =
        elementRect.left >= containerRect.left &&
        elementRect.right <= containerRect.right;

      return isVisibleVertically && isVisibleHorizontally;
    }

    const scrollElement = document.querySelector(".server-config__left__content")
    const innerElements = document.getElementsByClassName("server-config__left__content__section")

    const firstEle = document.querySelector("#content__section_0")
    const targetElement = document.querySelector(".server-config__left__selector");

    let intervalId
    const isMobileView = window.innerWidth < 768

    if (!scrollElement || !innerElements || !firstEle || !targetElement) return

    function elementScrollDetermine(curentElement) {
      if (!isElementCompletelyVisible(scrollElement, curentElement)) return;
      const childSize = curentElement.getBoundingClientRect();
      const parentSize = scrollElement.getBoundingClientRect();
      targetElement.style.top = `${(childSize.top - parentSize.top) - 24}px`;
      targetElement.style.height = `${curentElement.offsetHeight}px`;
      targetElement.style.width = `${curentElement.offsetWidth}px`;
    }


    targetElement.style.height = `${firstEle.offsetHeight}px`;
    targetElement.style.width = `${firstEle.offsetWidth}px`;

    if (scrollElement) {
      scrollElement.addEventListener("scroll", (event) => {
        event.preventDefault()
        const totalScrollableHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
        const currentScrollPosition = scrollElement.scrollTop;
        const scrollProgress = Number(((currentScrollPosition / totalScrollableHeight) * 100));
        if (scrollProgress === 0) {
          scrollElement.classList.add('top');
          scrollElement.classList.remove('bottom');
        } else if (scrollProgress === 100) {
          scrollElement.classList.add('bottom');
          scrollElement.classList.remove('top');
        } else {
          scrollElement.classList.remove('top');
          scrollElement.classList.remove('bottom');
        }
        const INIT_SCROLL_PROGRESS = 4

        // These ranges are nothing but cumilative summation of the clients heights & conversion of them into percentages, of each element that is in the scroll container.
        // For summation reference you can go through beginIntervals() function 
        // For percentage conversion refer to how scrollProgress is calculated.

        const ranges = [
          { min: 4, max: 15, index: 1 },
          { min: 15, max: 42, index: 2 },
          { min: 42, max: 60, index: 3 },
          { min: 60, max: 88, index: 4 },
          { min: 88, max: 100, index: 5 }
        ];

        if (scrollProgress < INIT_SCROLL_PROGRESS) {
          targetElement.style.top = "0px";
          targetElement.style.height = `${innerElements[0].offsetHeight}px`;
          targetElement.style.width = `${innerElements[0].offsetWidth}px`;
        } else {
          for (const range of ranges) {
            if (scrollProgress > range.min && scrollProgress <= range.max) {
              const curentElement = innerElements[range.index];
              elementScrollDetermine(curentElement)
              break;
            }
          }
        }

      })

      scrollElement.addEventListener("mouseenter", () => {
        clearIntervals()
      })


      window.addEventListener("scroll", () => {
        if (!!innerElements.length && isInViewport(scrollElement) && !isMobileView) {
          beginIntervals()
        } else {
          scrollElement.scrollTop = 0
          clearIntervals()
        }
      })
    }

    if (innerElements && !!innerElements.length && scrollElement) {
      const elements = Array.from(innerElements);
      elements.forEach((element, index) => {
        element.addEventListener(("mouseenter"), () => {
          if (!isElementCompletelyVisible(scrollElement, element)) return
          const parentSize = scrollElement.getBoundingClientRect();
          const childSize = element.getBoundingClientRect();
          targetElement.style.top = `${(childSize.top - parentSize.top) - 24}px`
          targetElement.style.height = `${element.offsetHeight}px`;
          targetElement.style.width = `${element.offsetWidth}px`;
        })
      })
    }


    function beginIntervals() {
      if (intervalId) return;
      let currentIndex = 0
      let scrollHeight = innerElements[currentIndex].clientHeight
      currentIndex += 1

      intervalId = setInterval(function () {
        targetElement.style.height = `${innerElements[currentIndex].offsetHeight}px`;
        targetElement.style.width = `${innerElements[currentIndex].offsetWidth}px`;
        if (currentIndex === innerElements.length - 1) {
          scrollElement.scrollTop = scrollElement.scrollHeight
          scrollHeight = innerElements[currentIndex].clientHeight
          currentIndex = 0
          return
        }
        else if (currentIndex !== 0) {
          scrollElement.scrollTop = scrollHeight
          scrollHeight += innerElements[currentIndex].clientHeight
        } else {
          scrollElement.scrollTop = 0
          scrollHeight = innerElements[currentIndex].clientHeight
        }
        currentIndex += 1
      }, 1500);
    }

    function clearIntervals() {
      clearInterval(intervalId);
      intervalId = null
      if (firstEle && targetElement) {
        targetElement.style.height = `${firstEle.offsetHeight}px`;
        targetElement.style.width = `${firstEle.offsetWidth}px`;
        scrollElement.scrollTop = 0
        targetElement.style.top = "0px"
      }
    }
  }
}
