import gsap from "gsap";
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { ScrollTrigger } from "gsap/all";

export default class Global {
  init() {
    this._header();
    this._mobilemenu();
    this._slides()
  }

  _header() {
    // using isMarlinPage so that the nav logic for main dosen't effect other page's navbar.
    // Using scrollTop < | > 800 in the conditional statements to manipulate the existing logic to fit our requirement.
    const header = document.querySelector(".header");
    const height = 0;
    const headerContent = document.querySelector(".header__main");
    const isMarlinPage = document.querySelector("#marlin-main")
    let lastScrollTop = 0;
    let scrollTimeout

    function scrollStopped(scrollStopped) {

      if (header && headerContent && !scrollStopped) {
        header.classList.add("is-scrolled");
      }
    }

    window.addEventListener("scroll", () => {
      gsap.registerPlugin(ScrollTrigger);
      const scrollTop = window.scrollY;
      let isInteractingNav = false
      const trigger = ScrollTrigger.getAll();
      const isFrameworkPinned = trigger.some((val) => val.vars.trigger === "#frameworks__section" && val.isActive !== undefined && val.isActive)
      
      if (header && headerContent) {
        header.addEventListener("mouseover", () => {
          isInteractingNav = true
        })
        header.addEventListener("mouseout", () => {
          isInteractingNav = false
        })
        if (scrollTop > height && !isFrameworkPinned) {
          headerContent.classList.add("is-fixed");
          if (isMarlinPage) {
            if (scrollTop > 800) {
              headerContent.classList.add("bg-white");
            } else {
              headerContent.classList.remove("bg-white");
            }
          } else {
            headerContent.classList.add("bg-white");
          }
        } else {
          headerContent.classList.remove("is-fixed");
          headerContent.classList.remove("bg-white");
        }
        if ((scrollTop < lastScrollTop || scrollTop < height) && !isFrameworkPinned) {
          header.classList.remove("is-scrolled");
        } else {
          if (isMarlinPage) {
            if (scrollTop > 500) {
              header.classList.add("is-scrolled");
            }
          } else {
            header.classList.add("is-scrolled");
          }

        }

      }
      lastScrollTop = scrollTop;
      clearTimeout(scrollTimeout);
      if (isMarlinPage && scrollTop > 500) {
        scrollTimeout = setTimeout(() => {
          scrollStopped(isInteractingNav)
        }, 2000);
      }

    });
  }

  _mobilemenu() {
    const menuItems = document.querySelectorAll(
      ".menu-mobile__main__content__button"
    );
    const menuMain = document.querySelector(".menu-mobile__main");
    const menuSubmenus = document.querySelector(".menu-mobile__submenus");
    const menuSubmenuItems = document.querySelectorAll(
      ".menu-mobile__submenus__item"
    );
    const menuBackButton = document.querySelectorAll(
      ".menu-mobile__submenus__item__button"
    );
    const burger = document.querySelector(".header__main__burger")
    const mobileMenu = document.querySelector(".menu-mobile");
    const headerMain = document.querySelector(".header__main") || document.querySelector(".nav--bar");

    menuItems.forEach(function (menuItem) {
      menuItem.addEventListener("click", function () {
        const menuItemId = this.getAttribute("data-id");
        const matchingSubmenuItem = menuSubmenus.querySelector(
          `.menu-mobile__submenus__item[data-id="${menuItemId}"]`
        );

        const tl = gsap.timeline();

        tl.fromTo(
          menuMain,
          { alpha: 1, translateX: 0 },
          {
            alpha: 0,
            duration: 0.15,
            translateX: "-20px",
            ease: "sine",
          }
        )
          .set(menuMain, { display: "none" })
          .set(menuSubmenuItems, { display: "none" })
          .set(matchingSubmenuItem, { display: "block" })
          .set(menuSubmenus, { display: "block" })
          .fromTo(
            menuSubmenus,
            { alpha: 0, translateX: "20px" },
            {
              alpha: 1,
              duration: 0.15,
              translateX: 0,
              ease: "sine",
            }
          );
      });
    });

    menuBackButton.forEach(function (menuItem) {
      menuItem.addEventListener("click", function () {
        const tl = gsap.timeline();

        tl.fromTo(
          menuSubmenus,
          { alpha: 1, translateX: 0 },
          {
            alpha: 0,
            duration: 0.15,
            translateX: "20px",
            ease: "sine",
          }
        )
          .set(menuSubmenus, { display: "none" })
          .set(menuMain, { display: "flex" })
          .fromTo(
            menuMain,
            { alpha: 0, translateX: "-20px" },
            {
              alpha: 1,
              duration: 0.15,
              translateX: 0,
              ease: "sine",
            }
          );
      });
    });

    if (burger) {
      burger.addEventListener("click", function () {
        if (this.classList.contains("is-active")) {
          this.classList.remove("is-active");
          this.style.zIndex = 1;
          if (headerMain) {
            headerMain.classList.remove("is-mobile-menu-active");
          }
          const tl = gsap.timeline();

          tl.fromTo(
            mobileMenu,
            { translateY: 0 },
            { translateY: "-100%", duration: 0.3, ease: "sine" }
          )
            .set(document.body, { overflow: "visible" })
            .set(mobileMenu, { display: "flex" });
        } else {
          this.classList.add("is-active");
          this.style.zIndex = 108;
          const tl = gsap.timeline();
          if (headerMain) {
            headerMain.classList.add("is-mobile-menu-active");
          }

          tl.set(mobileMenu, { display: "flex" })
            .set(document.body, { overflow: "hidden" })
            .fromTo(
              mobileMenu,
              { translateY: "-100%" },
              { translateY: 0, duration: 0.3, ease: "sine" }
            );
        }
      });
    }
  }

  _slides() {
    sliders('.slides-blue__slider')
    sliders('.slides-blue__slider.gateways')
    sliders('.slides__slider.oyster', '.slides .slider-nav__btn--next', '.slides .slider-nav__btn--prev')
    sliders('.table-slides__slider-mobile', '.table-slides__nav .slider-nav__btn--next', '.table-slides__nav .slider-nav__btn--prev', 16)
  }
}

export function sliders(className, nextEl = '.slides-blue .slider-nav__btn--next', prevEl = '.slides-blue .slider-nav__btn--prev', spaceBetween = 24) {
  let sliders = document.querySelectorAll(className);

  sliders.forEach(function (slider) {
    if (slider) {
      new Swiper(slider, {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: spaceBetween,
        grabCursor: true,
        touchEventsTarget: 'container',
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      });
    }
  });

}