import gsap from "gsap";
import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";
import ResizeObserver from "resize-observer-polyfill";

window.ResizeObserver = ResizeObserver;

export default class Home {
  init() {
    this._tabs();
    this._sliders();
    this._resourcesSliders();
    this._accordionHome();
  }

  _tabs() {
    let tabs = document.querySelectorAll(
      ".leading-communities__tabs__tabs__item"
    );
    let indicatorBar = document.querySelector(
      ".leading-communities__tabs__tabs__indicator"
    );
    let currentIndex = 0;
    let interval = 5000;
    let listAnimationTimeout;
    let isAllTab = document.querySelector("button[data-tab-all='true']");

    let tabsTl = gsap.timeline({});

    // function changeTab() {
    //   let nextIndex = (currentIndex + 1) % tabs.length;
    //   if (!tabs[nextIndex]) return;
    //   if (document.hasFocus()) {
    //     tabs[nextIndex].click();
    //     currentIndex = nextIndex;
    //   } else {
    //     listAnimationTimeout = setTimeout(changeTab, interval);
    //   }
    // }

    tabs.forEach(function (tab) {
      tab.addEventListener("click", function () {
        // clearTimeout(listAnimationTimeout);
        let tabContent = document.querySelector(
          ".leading-communities__tabs__content__item[data-tab*='" +
            this.getAttribute("data-tab-id") +
            "']"
        );
        let nextIndex =
          (Number(this.getAttribute("data-tab-id")) - 1) % tabs.length;
        currentIndex = nextIndex;

        if (!this.classList.contains("is-active")) {
          // Assuming 'filterItem' and 'filteredItems' are previously defined
          tabs.forEach(function (otherTab) {
            otherTab.classList.remove("is-active");
          });
          this.classList.add("is-active");

          let tabsContent = document.querySelectorAll(
            ".leading-communities__tabs__content__item"
          );

          tabsTl
            .to(tabsContent, {
              duration: 0.3,
              alpha: 0,
              translateY: 10,
              ease: "power3",
            })
            .set(tabsContent, {
              duration: 0,
              display: "none",
              ease: "power3",
            })
            .set(tabContent, {
              duration: 0,
              display: "flex",
              ease: "power3",
            })
            .set(tabContent, {
              duration: 0,
              display: "flex",
              ease: "power3",
            })
            .to(tabContent, {
              duration: 0.3,
              alpha: 1,
              translateY: 0,
              ease: "power3",
            });
        }
        // Schedule the next opening
        // listAnimationTimeout = setTimeout(changeTab, interval);

        indicator();
      });
    });

    indicator();

    function indicator() {
      let activeButton = document.querySelector(
        ".leading-communities__tabs__tabs__item.is-active"
      );
      if (!activeButton) return;
      let indicatorWidth = activeButton.offsetWidth;
      let indicatorOffset = activeButton.offsetLeft;

      indicatorBar.style.width = indicatorWidth + "px";
      indicatorBar.style.transform = "translateX(" + indicatorOffset + "px)";
      indicatorBar.style.opacity = "1";
    }

    // if (isAllTab) {
    //   listAnimationTimeout = setTimeout(changeTab, interval);
    // }
  }

  _sliders() {
    var swiper = new Swiper(".homeSwiper", {
      modules: [Autoplay],
      autoplay: {
        delay: 2500,
      },
      loop: true,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 24,
      touchEventsTarget: "container",
      breakpoints: {
        425: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 5,
        },
      },
    });
  }

  _resourcesSliders() {
    var swiper = new Swiper(".mainResourcesSwiper", {
      modules: [Navigation],
      slidesPerView: 'auto',
      spaceBetween: 24,
      grabCursor: true,
      touchEventsTarget: "container",
      navigation: {
        nextEl: ".slides .slider-nav__btn--next",
        prevEl: ".slides .slider-nav__btn--prev",
      },
    });
  }

  _accordionHome() {
    let accordions = document.querySelectorAll(".accordion-home");

    accordions.forEach(function (accordion) {
      let accordionTrigger = accordion.querySelector(".accordion__trigger");
      let accordionTriggerSvg = accordion.querySelector(
        ".accordion__trigger"
      ).firstElementChild;
      let accordionContent = accordion.querySelector(".accordion__content");

      accordionTrigger.addEventListener("click", function () {
        let accordionTl = gsap.timeline();
        let accordionTriggerSvgTl = gsap.timeline();
        if (accordion.classList.contains("is-active")) {
          accordionTriggerSvgTl.clear();
          accordion.classList.remove("is-active");
          accordionTl.to(accordionContent, {
            duration: 0.3,
            height: 0,
            ease: "power3",
          });
          accordionTriggerSvgTl.to(accordionTriggerSvg, {
            duration: 0.3,
            rotate: 0,
            ease: "power3",
          });
        } else {
          accordion.classList.add("is-active");
          accordionTriggerSvgTl.clear();
          accordionTl.to(accordionContent, {
            duration: 0.3,
            height: "auto",
            ease: "power3",
          });
          accordionTriggerSvgTl.to(accordionTriggerSvg, {
            duration: 0.3,
            rotate: 90,
            ease: "power3",
          });
        }
      });
    });
  }
}
